<template>
  <div>
    <Videos
      :videoContents="videoContents"
      :imageContents="imageContents"
      :audioContents="AudioContents"
      :textContents="TextContents"
      :activeTab="activeTab"
    />
  </div>
</template>
<style></style>
<script>
import { core } from "../../config/pluginInit"
import Videos from "./MovieCategoryPage/videos"
import ApiService from "../../services/api"

export default {
  name: "MovieCategory",
  data() {
    return {
      activeTab: "images",
      contents: [],
      videoContents: [],
      imageContents: [],
      AudioContents: [],
      TextContents: []
    }
  },
  components: {
    Videos
  },
  methods: {
    getContentBytCategory() {
      ApiService.getContentByCategory(this.$route.params.category).then(
        response => {
          this.$store.commit("setPoints", response.data.user_points)
          this.contents = response.data.data
          this.videoContents = this.contents.filter(content => {
            return content.content_type === "Video"
          })
          this.imageContents = this.contents.filter(content => {
            return content.content_type === "Image"
          })
          this.AudioContents = this.contents.filter(content => {
            return content.content_type === "Audio"
          })
          this.TextContents = this.contents.filter(content => {
            return content.content_type === "Text"
          })

          if (this.videoContents.length > 0) {
            this.activeTab = "videos"
          } else if (this.AudioContents.length > 0) {
            this.activeTab = "audios"
          } else if (this.imageContents.length > 0) {
            this.activeTab = "images"
          } else if (this.TextContents.length > 0) {
            this.activeTab = "texts"
          }
        }
      )
    }
  },
  created() {
    core.index()
    this.getContentBytCategory()
  }
}
</script>
