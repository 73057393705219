var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"iq-upcoming-movie"}},[_c('br'),_c('br'),_c('br'),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"overflow-hidden",attrs:{"sm":"12"}},[_c('div',{staticClass:"iq-main-header d-flex align-items-center justify-content-between",attrs:{"dir":"rtl"}},[(_vm.imageContents.length > 0)?_c('h4',{staticClass:"main-title"},[_vm._v(" "+_vm._s(_vm.imageContents[0]["content_category"]?.[_vm.$i18n.locale])+" ")]):_vm._e(),(_vm.imageContents.length == 0 && _vm.videoContents.length > 0)?_c('h4',{staticClass:"main-title"},[_vm._v(" "+_vm._s(_vm.videoContents[0]["content_category"]?.[_vm.$i18n.locale])+" ")]):_vm._e(),(
              _vm.imageContents.length == 0 &&
              _vm.videoContents.length == 0 &&
              _vm.audioContents.length > 0
            )?_c('h4',{staticClass:"main-title"},[_vm._v(" "+_vm._s(_vm.audioContents[0]["content_category"]?.[_vm.$i18n.locale])+" ")]):_vm._e()]),_c('div',{staticClass:"tabs",attrs:{"dir":"rtl"}},[(_vm.hasItems('imageContents'))?_c('button',{class:{ active: _vm.activeTab === 'images' },on:{"click":function($event){_vm.activeTab = 'images'}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? "Images" : _vm.$i18n.locale == "ku" ? "وێنە" : "صورة")+" ")]):_vm._e(),(_vm.hasItems('videoContents'))?_c('button',{class:{ active: _vm.activeTab === 'videos' },on:{"click":function($event){_vm.activeTab = 'videos'}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? "Videos" : _vm.$i18n.locale == "ku" ? "ڤیدیۆ" : "فيديو")+" ")]):_vm._e(),(_vm.hasItems('audioContents'))?_c('button',{class:{ active: _vm.activeTab === 'audios' },on:{"click":function($event){_vm.activeTab = 'audios'}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? "Audio" : _vm.$i18n.locale == "ku" ? "سروود" : "أناشيد")+" ")]):_vm._e()]),_c('br'),_c('div',{staticClass:"favorites-contens"},[(_vm.activeTab === 'videos')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'videos'),expression:"activeTab === 'videos'"}],staticClass:"favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"},_vm._l((_vm.videoContents),function(item,index){return _c('li',{key:index,staticClass:"slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"block-images position-relative"},[_c('div',{staticClass:"img-box"},[_c('div',[(item.content_thumbnail != null)?_c('img',{staticClass:"img-fluid",attrs:{"src":item.content_thumbnail,"alt":""}}):_c('img',{staticClass:"img-fluid",attrs:{"alt":"","src":require("../../../assets/images/thumbnail.jpg")}}),_c('div',{staticClass:"block-description1"},[_c('h6',{staticClass:"iq-title"},[_c('a',{on:{"click":function($event){return _vm.gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                _vm.$route.params.categoryId
                            )}}})]),(_vm.$i18n.locale == 'en')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                _vm.$route.params.categoryId
                            )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}},[_vm._v(" Play Now ")])])]):_vm._e(),(_vm.$i18n.locale == 'ku')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                _vm.$route.params.categoryId
                            )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}}),_c('span',{staticStyle:{"font-family":"'Speda'"}},[_vm._v(" ڤیدیۆکە لێبدە ")])])]):_vm._e(),(_vm.$i18n.locale == 'ar')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                _vm.$route.params.categoryId
                            )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}}),_c('span',{staticStyle:{"font-family":"'Janna-LT'"}},[_vm._v(" شغل الفيديو ")])])]):_vm._e()])])])])])}),0):_vm._e(),(_vm.activeTab === 'audios')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'audios'),expression:"activeTab === 'audios'"}],staticClass:"favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"},_vm._l((_vm.audioContents),function(item,index){return _c('li',{key:index,staticClass:"slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"block-images position-relative"},[_c('div',{staticClass:"img-box"},[_c('div',[(item.content_thumbnail != null)?_c('img',{staticClass:"img-fluid",attrs:{"src":item.content_thumbnail,"alt":""}}):_c('img',{staticClass:"img-fluid",attrs:{"alt":"","src":require("../../../assets/images/thumbnail.jpg")}}),_c('div',{staticClass:"block-description1"},[_c('h6',{staticClass:"iq-title"},[_c('a',{on:{"click":function($event){return _vm.gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                _vm.$route.params.categoryId
                            )}}})]),(_vm.$i18n.locale == 'en')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                _vm.$route.params.categoryId
                            )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}},[_vm._v(" Play Now ")])])]):_vm._e(),(_vm.$i18n.locale == 'ku')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                _vm.$route.params.categoryId
                            )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}}),_c('span',{staticStyle:{"font-family":"'Speda'","position":"relative","top":"-10px"}},[_vm._v(" سروودەکە لێبدە ")])])]):_vm._e(),(_vm.$i18n.locale == 'ar')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                              '/detail/' +
                                item.id +
                                '/' +
                                _vm.$route.params.categoryId
                            )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}}),_c('span',{staticStyle:{"font-family":"'Janna-LT'"}},[_vm._v(" شغل ")])])]):_vm._e()])])])])])}),0):_vm._e(),(_vm.activeTab === 'images')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'images'),expression:"activeTab === 'images'"}],staticClass:"favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"},_vm._l((_vm.imageContents),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(item.content_url[_vm.$i18n.locale] != null),expression:"item.content_url[$i18n.locale] != null"}],key:index,staticClass:"slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"block-images position-relative"},[_c('div',{staticClass:"img-box"},[_c('div',[_c('div',[_c('img',{staticClass:"img-fluid",attrs:{"src":item.content_thumbnail,"alt":""}}),_c('div',{staticClass:"block-description1"},[_c('h6',{staticClass:"iq-title"},[_c('a',{on:{"click":function($event){return _vm.gotoPage(
                                '/detail/' +
                                  item.id +
                                  '/' +
                                  _vm.$route.params.categoryId
                              )}}})]),(_vm.$i18n.locale == 'en')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                                '/detail/' +
                                  item.id +
                                  '/' +
                                  _vm.$route.params.categoryId
                              )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}},[_vm._v(" View ")])])]):_vm._e(),(_vm.$i18n.locale == 'ku')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                                '/detail/' +
                                  item.id +
                                  '/' +
                                  _vm.$route.params.categoryId
                              )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}}),_c('span',{staticStyle:{"font-family":"'Speda'"}},[_vm._v(" بینین ")])])]):_vm._e(),(_vm.$i18n.locale == 'ar')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                                '/detail/' +
                                  item.id +
                                  '/' +
                                  _vm.$route.params.categoryId
                              )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}}),_c('span',{staticStyle:{"font-family":"'Janna-LT'"}},[_vm._v("عرض ")])])]):_vm._e()])])])])])])}),0):_vm._e(),(_vm.activeTab === 'texts')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'texts'),expression:"activeTab === 'texts'"}],staticClass:"favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"},_vm._l((_vm.textContents),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(item.content_url[_vm.$i18n.locale] != null),expression:"item.content_url[$i18n.locale] != null"}],key:index,staticClass:"slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"block-images position-relative"},[_c('div',{staticClass:"img-box"},[_c('div',[_c('div',[_c('img',{staticClass:"img-fluid",attrs:{"src":item.content_thumbnail,"alt":""}}),_c('div',{staticClass:"text-type"},[_vm._v(" "+_vm._s(item.content_title[_vm.$i18n.locale])+" ")]),_c('div',{staticClass:"block-description1"},[_c('h6',{staticClass:"iq-title"},[_c('a',{on:{"click":function($event){return _vm.gotoPage(
                                '/detail/' +
                                  item.id +
                                  '/' +
                                  _vm.$route.params.categoryId
                              )}}})]),(_vm.$i18n.locale == 'en')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                                '/detail/' +
                                  item.id +
                                  '/' +
                                  _vm.$route.params.categoryId
                              )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}},[_vm._v(" View ")])])]):_vm._e(),(_vm.$i18n.locale == 'ku')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                                '/detail/' +
                                  item.id +
                                  '/' +
                                  _vm.$route.params.categoryId
                              )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}}),_c('span',{staticStyle:{"font-family":"'Speda'"}},[_vm._v(" بینین ")])])]):_vm._e(),(_vm.$i18n.locale == 'ar')?_c('div',{staticClass:"hover-buttons"},[_c('a',{staticClass:"btn btn-hover",attrs:{"role":"button"},on:{"click":function($event){return _vm.gotoPage(
                                '/detail/' +
                                  item.id +
                                  '/' +
                                  _vm.$route.params.categoryId
                              )}}},[_c('i',{staticClass:"fa fa-play mr-1",attrs:{"aria-hidden":"true"}}),_c('span',{staticStyle:{"font-family":"'Janna-LT'"}},[_vm._v("عرض ")])])]):_vm._e()])])])])])])}),0):_vm._e()])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }